// ลบได้
import React from "react";
import PropTypes from "utils/propTypes";

import classNames from "classnames";

import { Card, CardTitle, CardSubtitle, CardText, CardBody } from "reactstrap";

import Avatar from "../Avatar";

const UserCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  email,
  text,
  children,
  className,
  ...restProps
}) => {
  // const classes = classNames('bg-gradient-theme', className);

  return (
    <Card inverse {...restProps} className="text-dark">
      <CardBody className="d-flex justify-content-center align-items-center flex-column">
        <Avatar src={avatar} size={avatarSize} className="mb-2" />
        <CardTitle>
          <b style={{ fontSize: 15 }}>{title}</b>
        </CardTitle>
        <CardSubtitle>{email}</CardSubtitle>
        <CardText>{subtitle}</CardText>
        <CardText>
          <small>{text}</small>
        </CardText>
      </CardBody>
      {children}
    </Card>
  );
};

UserCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  email: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
};

UserCard.defaultProps = {
  avatarSize: 80,
};

export default UserCard;
