// ส่วนหัวของเว็บ
import Avatar from "components/Avatar";
import { UserCard } from "components/Card";
import Notifications from "components/Notifications";
import SearchInput from "components/SearchInput";
import { notificationsData } from "demos/header";
import withBadge from "hocs/withBadge";
import { withRouter } from "react-router-dom";
import React from "react";
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
  MdPersonOutline,
  MdVerticalAlignCenter,
} from "react-icons/md";
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from "reactstrap";
import bn from "utils/bemnames";
import { webUrl } from "../../assets/config/config_const";
import IdleTimer from "react-idle-timer";

const bem = bn.create("header");

const MdNotificationsActiveWithBadge = withBadge({
  size: "md",
  color: "primary",
  style: {
    top: -10,
    right: -10,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  children: <small>5</small>,
  // children: แจ้งเตือนว่ามี 5 ข้อความ
})(MdNotificationsActive);

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 1000 * 60 * 60 * 3,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  _onAction(e) {
    // console.log("user did something", e);
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    // console.log("user is active", e);
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    // console.log("user is idle", e);
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      // console.log("bye bye", this.props);
      localStorage.removeItem("login");
      this.props.history.push("/");
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }

  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector(".cr-sidebar").classList.toggle("cr-sidebar--open");
  };

  render() {
    const { isNotificationConfirmed } = this.state;

    if (
      localStorage.getItem("login") === "undefined" ||
      localStorage.getItem("login") === null
    ) {
      this.props.history.push("/");
      return null;
    }
    const { fullname, org_name, email } = JSON.parse(
      localStorage.getItem("login")
    );

    const logout = () => {
      localStorage.removeItem("login");
      this.props.history.push("/");
    };

    const profile = () => {
      this.props.history.push("/Profile");
    };

    return (
      <Navbar light expand className={bem.b("bg-white")}>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        {/*Button navbar sidler (ปุ่มเมนูสั่งให้เมนูยุบขึ้นลง) */}
        <Nav navbar className="mr-2">
          <Button outline onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
            {/* MdClearAll เป็นไอคอน*/}
            {/* size={25} คือปรับขนาดรูปแฮมเบอร์เกอร์ให้มี size ตามที่เราต้องการ */}
          </Button>
        </Nav>
        {/* End  Button navbar sidler*/}

        {/* Start Search (กล่องค้นหา) */}
        {/* <Nav navbar >
          <SearchInput />
        </Nav> */}
        {/*End Search จบกล่องค้นหา */}

        {/* --------------------------------------------------------------------------------------------------- */}

        <Nav navbar className={bem.e("nav-right")}>
          {/* ส่วนค้นหา */}
          {/* <SearchInput /> */}
          {/* จบส่วนค้นหา */}

          {/*Start  bell Alert (ระฆังแจ้งเตือนเวลามีแจ้งเตือนมันก็จะเด้งแจ้งเตือนขึ้นมาตรงนี้)*/}
          {/* <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink>
            <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover>
          </NavItem> */}
          {/* End  bell Alert */}

          {/*Start icon user  (เป็นไอคอนที่ดึงรูป user มาแล้วๆมันจะมีเมนูให้เลือกอีก)*/}
          <NavItem>
            <NavLink id="Popover2">
              {/* <center>{org_name}</center>&nbsp;&nbsp;&nbsp; */}
              <Avatar
                size={25}
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }} //ความกว้างเมื่อคลิก icon
            >
              <PopoverBody className="p-0 border-light text-dark">
                <UserCard
                  title={fullname}
                  email={email}
                  subtitle={org_name}
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem
                      tag="button"
                      action
                      className="border-light"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = `${webUrl}/Profile`;
                      }}
                    >
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    <ListGroupItem
                      tag="button"
                      action
                      className="border-light"
                      onClick={() => {
                        logout();
                      }}
                    >
                      <MdExitToApp /> Sign Out
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
          {/* End  icon user*/}
        </Nav>
      </Navbar>
    );
  }
}

export default withRouter(Header);
