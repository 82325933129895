// import logo200Image from 'assets/img/logo/logo_200.png';
import sidebarBgImage from "assets/img/sidebar/sidebar-3.jpg";
// import SourceLink from 'components/SourceLink';
import React from "react";
// import { FaGithub } from 'react-icons/fa';
import {
  MdExtension,
  MdKeyboardArrowDown,
  // MdPages,
  MdRadioButtonChecked,
  MdSend,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from "reactstrap";
import bn from "utils/bemnames";

import { BsHouseDoorFill, BsFillPeopleFill, BsChatFill } from "react-icons/bs";

import { GrReddit } from "react-icons/gr";
import { RiBankLine } from "react-icons/ri";
import { FaHashtag } from "react-icons/fa";
import { FaList } from "react-icons/fa";
import web_logo from "assets/img/logo/cuicui-logo.png";
import { webUrl } from "../../assets/config/config_const";
// const sidebarBackground = {
//   backgroundImage: `url("${sidebarBgImage}")`,  //พื้นหลัง sider bar
//   backgroundSize: 'cover',
//   backgroundRepeat: 'no-repeat',
// };

const bem = bn.create("sidebar");

// const role = JSON.parse(localStorage.getItem('login'))?.role

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenComponents: true,
      isOpenContents: true,
      isOpenPages: true,
    };
  }

  handleClick = (name) => () => {
    this.setState((prevState) => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  render() {
    const role = JSON.parse(
      localStorage.getItem("login") === "undefined" ||
        localStorage.getItem("login") === null
        ? "{}"
        : localStorage.getItem("login")
    )?.role;
    // console.log('my new ROLE',role)
    // const navComponents = role==1 ? [] : [
    //   { to: '/Topic', name: 'Topic', exact: false, Icon: MdRadioButtonChecked },
    //   { to: '/Dialogs', name: 'Dialog', exact: false, Icon: BsChatFill },
    //   // { to: '/MatchingDialog', name: 'Botadd', exact: false, Icon: GrReddit },
    //   { to: '/Bots', name: 'Bot', exact: false, Icon: GrReddit },
    // ];

    // const navContents = role==3 ? [] : role==2 ? [{ to: '/ManageUser', name: 'User', exact: false, Icon: BsFillPeopleFill }] :  [
    //   { to: '/ManageOrganizations', name: 'Organization', exact: false, Icon: RiBankLine },
    //   { to: '/ManageUser', name: 'User', exact: false, Icon: BsFillPeopleFill },
    // ];

    // const pageContents = [
    //   { to: '/', name: 'Fronthome', exact: false, Icon: BsFillPeopleFill },
    // ];
    const manageOrg = {
      to: "/ManageOrganizations",
      name: "Organization",
      exact: false,
      Icon: RiBankLine,
    };
    const manageUser = {
      to: "/ManageUser",
      name: "User",
      exact: false,
      Icon: BsFillPeopleFill,
    };
    const Dialog = {
      to: "/Dialogs",
      name: "Dialog",
      exact: false,
      Icon: BsChatFill,
    };
    const Bot = { to: "/Bots", name: "Bot", exact: false, Icon: GrReddit };
    const Topic = { to: "/Topic", name: "Topic", exact: false, Icon: FaList };
    const Tag = { to: "/Tag", name: "Tag", exact: false, Icon: FaHashtag };
    const BotToken = {
      to: "/BotToken",
      name: "Bot",
      exact: false,
      Icon: GrReddit,
    };
    // const Home = { to: '/Home', name: 'Home', exact: true, Icon: BsHouseDoorFill };
    // // name menu (ชื่อเมนูใหญ่)
    const navItems =
      role == 1
        ? [manageOrg, manageUser, BotToken]
        : role == 2
        ? [manageUser, Dialog, Bot, Topic, Tag]
        : [Dialog, Bot, Topic, Tag];
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e("content")}>
          {/* Start icon web (เป็นไอคอนเว็บที่เรากดแล้วไปหน้า Home ได้) */}
          <Navbar className="navbar-brand d-flex">
            {/* <a href={[2,3].includes(role) ? webUrl+"/Dashboard" : "#"}><img src={web_logo} width="170" height="60" className="pr-2" alt=""/></a> */}
            <img
              src={web_logo}
              width="170"
              height="60"
              className="pr-2"
              alt=""
            />
          </Navbar>
          {/* End icon web */}

          {/*Start menu sidebar */}
          <Nav vertical>
            {/* vertical คือ ตัวที่ทำให้ไฮไลท์เวลากดเลือกเมนูเต็ม navbar */}
            {/*Start Main menu (ส่วนของเมนูใหญ่ คืออันที่ไม่มีกดย่อยลงไป) */}
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className=""
                  // className="text-uppercase" คือทำให้ตัวอักษรตัวใหญ่ทั้งหมด
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            {/*End Main menu*/}

            {/* Start menu  Contents (ที่มีเมนูย่อยๆลงไปอีก)*/}
            {/* {navContents.length>1 &&
              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('Contents')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdSend className={bem.e('nav-item-icon')} />
                    <span className="">Manage</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenContents
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
            }
            <Collapse isOpen={this.state.isOpenContents}>
              {navContents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className=""
                      // className="text-uppercase" คือทำให้ตัวอักษรตัวใหญ่ทั้งหมด
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> */}
            {/* End menu  Contents */}

            {/*Start Menu Components (เป็นเมนู components ที่มีเมนูย่อยลงไปอีก)*/}
            {/* {navComponents.length>1 && role!=3 &&
              <NavItem
                className={bem.e('nav-item')}
                onClick={this.handleClick('Components')}
              >
                <BSNavLink className={bem.e('nav-item-collapse')}>
                  <div className="d-flex">
                    <MdExtension className={bem.e('nav-item-icon')} />
                    <span className=" align-self-start">Bot Design</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e('nav-item-icon')}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenComponents
                        ? 'rotate(0deg)'
                        : 'rotate(-90deg)',
                      transitionDuration: '0.3s',
                      transitionProperty: 'transform',
                    }}
                  />
                </BSNavLink>
              </NavItem>
            }
            <Collapse isOpen={this.state.isOpenComponents}>
              {navComponents.map(({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className=""
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              ))}
            </Collapse> */}
            {/* End  Menu Components*/}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
