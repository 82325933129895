import { Content, Footer, Header, Sidebar } from "components/Layout";
// import { HeaderHome } from "components/Layout/HeaderHome";
import React from "react";
// import {
//   MdImportantDevices,
//   // MdCardGiftcard,
//   MdLoyalty,
// } from 'react-icons/md';
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "utils/constants";

class MainLayout extends React.Component {
  static isSidebarOpen() {
    return document
      .querySelector(".cr-sidebar")
      ?.classList.contains("cr-sidebar--open");
  }

  // แก้ Error componentWillReceiveProps
  // componentWillReceiveProps({ breakpoint }) {
  //   if (breakpoint !== this.props.breakpoint) {
  //     this.checkBreakpoint(breakpoint);
  //   }
  // }

  // componentDidMount() {
  //   this.checkBreakpoint(this.props.breakpoint);

  //   setTimeout(() => {
  //     if (!this.notificationSystem) {
  //       return;
  //     }

  //     this.notificationSystem.addNotification({
  //       title: <MdImportantDevices />,
  //       message: 'Welome to CUICUI AI9',
  //       level: 'info',
  //     });
  //   }, 1500);

  //   setTimeout(() => {
  //     if (!this.notificationSystem) {
  //       return;
  //     }

  //     this.notificationSystem.addNotification({
  //       title: <MdLoyalty />,
  //       message:
  //         'CUICUI AI9 THE BEST AI',
  //       level: 'info',
  //     });
  //   }, 2500);
  // }

  componentDidMount() {
    this.openSidebar("open");
  }

  componentDidUpdate() {
    this.openSidebar("open");
  }

  // close sidebar when
  handleContentClick = (event) => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === "xs" ||
        this.props.breakpoint === "sm" ||
        this.props.breakpoint === "md")
    ) {
      this.openSidebar("close");
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case "xs":
      case "sm":
      case "md":
        return this.openSidebar("close");

      case "lg":
      case "xl":
      default:
        return this.openSidebar("open");
    }
  }

  openSidebar(openOrClose) {
    if (document.querySelector(".cr-sidebar")) {
      if (openOrClose === "open") {
        // if (openOrClose === 'open') { อันนี้คือสั่งให้ sider ตอนเปิดเว็บมาแล้วขึ้น
        // ส่วน close คือตอน sider สั่งให้ปิดก่อน กดปุ่มเมนูถึงจะขึ้นมาให้
        return document
          .querySelector(".cr-sidebar")
          .classList.add("cr-sidebar--open");
        // ของเดิมคือ .classList.add('cr-sidebar--open'); แต่แก้ใหม่ใช้ close จะทำให้เมื่อเปลี่ยนหน้าจอก็จะเริ่มต้นด้วยการปิด
      }
      document
        .querySelector(".cr-sidebar")
        .classList.remove("cr-sidebar--open");
    }
  }

  render() {
    // const { children } = this.props;
    const {
      children,
      location: { pathname },
    } = this.props;
    return (
      <main className="cr-app bg-light">
        {pathname !== "/" && <Sidebar />}
        <Content fluid onClick={this.handleContentClick}>
          {pathname !== "/" && <Header />}
          {/* children คือส่วน body ของเว็บ */}
          {children}
          {pathname !== "/" && <Footer />}
        </Content>

        <NotificationSystem
          dismissible={false}
          ref={(notificationSystem) =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />
      </main>
    );
  }
}

export default MainLayout;
