export const apiUrl = "https://cuicui.ai9.co.th";
export const webUrl = "https://cuicui.ai9.co.th";
export const editorUrl = "https://cuicui.ai9.co.th";
const forceLogout = () => {
  localStorage.removeItem("login");
  window.location.href = `${webUrl}/`;
};
export const checkSession = ({ url, body, response, method }) => {
  return fetch(`${apiUrl}${url}`, {
    method,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": (
        JSON.parse(localStorage.getItem("login")) || { accessToken: "" }
      ).accessToken,
    },
    redirect: "follow",
    body,
  })
    .then((data) => {
      //   console.log(data);
      if (data.status === 500) {
        forceLogout();
      }
      return data;
    })
    .then(response)
    .catch((e) => {
      console.log(e);
      forceLogout();
    });
};
