// import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import GAListener from "components/GAListener";
// import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import { MainLayout } from "components/Layout";
import PageSpinner from "components/PageSpinner";
// import AuthPage from 'pages/AuthPage';
import React from "react";
import componentQueries from "react-component-queries";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./styles/reduction.scss";

// page ที่สร้างเองใหม่
// const HomeUser = React.lazy(() => import('pages/HomeUser'));
const MatchingDialog = React.lazy(() => import("pages/BotsUser"));
const Dialogs = React.lazy(() => import("pages/Dialogs"));
const Topic = React.lazy(() => import("pages/Topic"));
const ManageUser = React.lazy(() => import("pages/ManageUser"));
const ManageOrganizations = React.lazy(() =>
  import("pages/ManageOrganizations")
);
const Fronthome = React.lazy(() => import("pages/Fronthome"));
const Bots = React.lazy(() => import("pages/Bots"));
const TransferList = React.lazy(() => import("pages/TransferList"));
const DashboardPage = React.lazy(() => import("pages/DashboardPage"));
const Tag = React.lazy(() => import("pages/Tag"));
const ConfigBotToken = React.lazy(() => import("pages/ConfigBotToken"));
const Profile = React.lazy(() => import("pages/EditProfile"));

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                {/* Error เพราะทำแบบนี้ <Route exact path="/Fronthome" component={Fronthome} />
               ของที่ถูกคือ  <Route exact path="/Fronthome "  render={() => <Fronthome />} /> */}
                {/* page ที่สร้างมาใหม่ */}
                {/* <Route exact path="/Home"  render={() => <HomeUser />} /> */}
                <Route
                  exact
                  path="/Dashboard"
                  render={() => <DashboardPage />}
                />
                {/* <Route exact path="/BotsUser"  render={() => <BotsUser />} /> */}
                <Route exact path="/Dialogs" render={() => <Dialogs />} />
                <Route exact path="/Topic" render={() => <Topic />} />
                <Route exact path="/ManageUser" render={() => <ManageUser />} />
                <Route
                  exact
                  path="/ManageOrganizations"
                  render={() => <ManageOrganizations />}
                />
                <Route exact path="/" render={() => <Fronthome />} />
                <Route exact path="/Bots" render={() => <Bots />} />
                <Route
                  exact
                  path="/TransferList"
                  render={() => <TransferList />}
                />
                <Route
                  exact
                  path="/MatchingDialog"
                  render={() => <MatchingDialog />}
                />
                <Route exact path="/Tag" render={() => <Tag />} />
                <Route
                  exact
                  path="/BotToken"
                  render={() => <ConfigBotToken />}
                />
                <Route exact path="/Profile" render={() => <Profile />} />
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};
// componentQueries เป็นตัวช่วย react ในการที่จะทำให้ code เปลี่ยนตามหน้าจอ
export default componentQueries(query)(App);
